import { createSlice } from "@reduxjs/toolkit"
import { ActionMode } from "../../../interface/EntityFaq";
import { IDiscountData } from "../../../interface/EntityDiscount";
import { ISenderType } from "../../../interface/EntitySenderType";

const initialState: {
    mode: ActionMode,
    data: ISenderType | null,
    deleteItem: ISenderType | null
} = {
    mode: "none",
    data: null,
    deleteItem: null
}


export const SenderTypeForm = createSlice({
    name: 'SenderTypeForm',
    initialState,
    reducers: {
        setSenderTypeFormMode: (state, action: { payload: ActionMode }) => {
            let newState = state;
            newState['mode'] = action.payload
            return newState;
        },
        emptySenderTypeFormMode: (state) => {
            let newState = state;
            newState['mode'] = 'none'
            return newState;
        },
        setSenderTypeFormData: (state, action: { payload: ISenderType }) => {
            let newState = state;
            newState['data'] = action.payload
            return newState;
        },
        emptySenderTypeFormData: (state) => {
            let newState = state;
            newState['data'] = null
            return newState;
        },
        setSenderTypeDeleteItem: (state, action: { payload: ISenderType }) => {
            let newState = state;
            newState['deleteItem'] = action.payload
            return newState;
        },


        emptySenderTypeDeleteItem: (state) => {
            let newState = state;
            newState['deleteItem'] = null
            return newState;
        },
    }
})

export const {
    setSenderTypeFormMode,
    emptySenderTypeFormMode,
    setSenderTypeFormData,
    emptySenderTypeFormData,
    setSenderTypeDeleteItem,
    emptySenderTypeDeleteItem
} = SenderTypeForm.actions

export default SenderTypeForm.reducer