import { RoleAddPropsApi, RoleListAllApi, RoleListApi, RoleListFilterApi, RoleRemovePropsApi, RoleUpdatePropsApi } from "../../interface/EntityRole";

import { ChangeToFormData, ChangeToQueryString } from "../../utility/Function";
import { ROLE_ALL_PATH, ROLE_PATH } from "../../config/ApiConf";
import { baseQueryWithReAuth } from "../ApiCal";
import { createApi } from '@reduxjs/toolkit/query/react'

export const RoleApi = createApi({
    reducerPath: 'RoleApi',
    baseQuery: baseQueryWithReAuth,

    tagTypes: ["Role"],

    endpoints: (builder) => ({
        Role: builder.query<RoleListApi, RoleListFilterApi>({
            query: (body) => ({
                url: ROLE_PATH+ChangeToQueryString(body),
                method: 'GET',

            })
            ,
            providesTags: ['Role'],
        }),

        RoleAll: builder.query<RoleListAllApi, void>({
            query: (body) => ({
                url: ROLE_ALL_PATH,
                method: 'GET',

            })
            ,
            providesTags: ['Role'],
        }),


        RoleAdd: builder.mutation<any, RoleAddPropsApi>({
            query: (body) => ({
                url: ROLE_PATH,
                method: 'POST',

                body: ChangeToFormData(body)
            }),
            invalidatesTags: ['Role'],
        }),

        RoleEdit: builder.mutation<void, any>({
            // query: ({ id, ...res }) => ({
            query: (body) => ({
                // url: ROLE_PATH + "/" + id,
                // url:"admin/permissionRoles/" + id,
                url:"admin/permissionRoles",
                method: 'POST',

                // body: (res)
                body
            }),
            invalidatesTags: ['Role'],
        }),

        RoleRemove: builder.mutation<void, RoleRemovePropsApi>({
            query: (body) => ({
                url: ROLE_PATH + "/" + body.id,
                method: 'DELETE',

            }),
            invalidatesTags: ['Role'],
        }),



    }),



})

export const {
    useRoleQuery,
    useRoleAllQuery,
    useRoleAddMutation,
    useRoleEditMutation,
    useRoleRemoveMutation
} = RoleApi


