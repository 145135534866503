import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReAuth } from '../ApiCal'
import { DISCOUNT_PATH, SENDER_TYPE_PATH } from '../../config/ApiConf'
import {
  IDiscount,
  IDiscountData,
  IDiscountListFilter,
  IListDiscountApi,
  IResponseDiscountDetails,
  IResponseGetUseDiscount
} from '../../interface/EntityDiscount'
import {
  changeToFormDataDiscount,
  changeToQueryParams
} from '../../utility/Function'
import { ID } from '../../interface/EntityCommon'
import {
  ResultAddSenderType,
  ResultGetSenderType,
  ResultGetSenderTypes
} from '../../interface/EntitySenderType'

export const SenderTypeApi = createApi({
  reducerPath: 'SenderTypeApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['SenderType'],
  endpoints: builder => ({
    getSenderTypeList: builder.query<ResultGetSenderTypes, void>({
      query: () => ({
        url: SENDER_TYPE_PATH,
        method: 'GET'
      }),
      providesTags: ['SenderType']
    }),
    getsenderType: builder.query<ResultGetSenderType, ID>({
      query: body => ({
        url: `admin/type-sender/${body.id}/get`,
        method: 'GET'
      }),
      providesTags: ['SenderType']
    }),
    // getDiscountUsers: builder.query<IResponseGetUseDiscount, ID>({
    //     query: (body) => ({
    //         url: DISCOUNT_PATH+`/get-use-discount/${body.id}`,
    //         method: 'GET',
    //     }),
    //     providesTags: ['Discount'],
    // }),
    addSenderType: builder.mutation<ResultAddSenderType, any>({
      query: body => ({
        url: 'admin/type-sender/insert',
        method: 'POST',
        body
      }),
      invalidatesTags: ['SenderType']
    }),
    editSenderType: builder.mutation<ResultAddSenderType, any>({
      query: body => ({
        url: `admin/type-sender/${body.id}/update`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['SenderType']
    }),
    deleteSenderType: builder.mutation<ResultAddSenderType, ID>({
      query: body => ({
        url: `admin/type-sender/${body.id}/delete`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['SenderType']
    }),
  changeDefaultSenderType: builder.mutation<ResultAddSenderType, {id:number,default:number}>({
      query: body => ({
        url: `admin/type-sender/${body.id}/update-default`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['SenderType']
    })
    // editDiscount: builder.mutation<void, IDiscountData>({
    //     query: ({ id, ...res }) => ({
    //         url: DISCOUNT_PATH + "/" + id,
    //         method: 'POST',
    //         body: changeToFormDataDiscount({...res,...{"_method":"PUT"}})

    //     }),
    //     invalidatesTags: ['Discount'],
    // }),
  })
})

export const {
  useGetSenderTypeListQuery,
  useAddSenderTypeMutation,
  useGetsenderTypeQuery,
  useEditSenderTypeMutation,
  useDeleteSenderTypeMutation,
  useChangeDefaultSenderTypeMutation
} = SenderTypeApi
