import Header from "../../components/discounts/header";
import ListData from "../../components/discounts/list/index";
import HeaderSenderTypePage from "../../components/sendType/header";
import ListTypeSender from "../../components/sendType/list";

export default function PageSenderType() {
    return (
            <div className="flexCol gap-4 pageLayout">
                <HeaderSenderTypePage/>
                <ListTypeSender/>
            </div>
    );
}