import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../data/store';
import { useEditSenderTypeMutation, useGetsenderTypeQuery } from '../../../data/services/SenderType';
import { ToastError, ToastSuccess } from '../../../utility/ToastFunction';
import { setSenderTypeFormMode } from '../../../data/slice/senderType/SenderTypeForm';
import ModalFull from '../../shared/modal/ModalFull';
import ButtonBrand from '../../shared/button/ButtonBrand';
import PageMarginBottom from '../../shared/Html/PageMarginBottom';
import FormItemSenderype from './FormItemSenderype';


export default function EditSenderType() {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.SenderTypeForm);
    const { data } = useGetsenderTypeQuery({ id: Data.data?.id ?? 0 }, { skip: Data.data === null })
    const dispatch = useDispatch()
    const [Edit, results] = useEditSenderTypeMutation();
    const [OpenAdd, setOpenAdd] = useState(false);

    const Schema = Yup.object().shape({
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useForm<any>({
        resolver: yupResolver(Schema),

    });

    const OnFinish: SubmitHandler<any> = (data) => {
        console.log(data)
        if (data?.title !== undefined) {
            let params: any = data

            Edit(params)
        }
    }

    useEffect(() => {
        if (Data.mode === 'edit' && data?.data !== undefined) {
            setOpenAdd(true);
            
            reset({
                id: data.data?.id,
                name: data.data?.name,
                title: data.data?.title,
                description: data.data?.description,
                cost: data.data?.cost,
                default: data.data?.default,
                user_type: data.data?.user_type,
            });
        } else {
            setOpenAdd(false);
        }
    }, [Data.mode, data, reset])



    useEffect(() => {
        if (results.isSuccess) {

            dispatch(setSenderTypeFormMode("none"))
            ToastSuccess(t("Common.successAlarm"))
            // window.location.reload();
        }

        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
            <ModalFull ChangeOpen={(open: boolean) => {
                setOpenAdd(open);
                dispatch(setSenderTypeFormMode(open ? "edit" : "none"));


            }} Open={OpenAdd} title={t("Common.editDiscount")}>
                <div className="FormModal !h-auto">

                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItemSenderype control={control} errors={errors} getValues={getValues} setValue={setValue} watch={watch} />

                        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={results.isLoading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>
                    <PageMarginBottom />
                </div>
            </ModalFull>
        
    )
}
