import { SyntheticEvent, useEffect, useState } from 'react'
import {
  useEditProfileMutation,
  useGetProfileQuery
} from '../../data/services/Profile'
import { IPropfile } from '../../interface/EntityProdile'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PersonIcon from '@mui/icons-material/Person'
import ShieldIcon from '@mui/icons-material/Shield'
import { useTranslation } from 'next-i18next'
import { divIcon } from 'leaflet'
import * as Yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ButtonBrand from '../../components/shared/button/ButtonBrand'
import InputTxt from '../../components/shared/form/input/InputTxt'
import PasswordTxt from '../../components/shared/form/input/PasswordTxt'
import EditPassword from '../../components/profile/form/editPassword'
import MobileInput from '../../components/shared/form/input/MobileInput'
import { ToastSuccess } from '../../utility/ToastFunction'

const PageProfile = () => {
  const { data, isLoading } = useGetProfileQuery()
  const [editProfile,resultEdit] = useEditProfileMutation()
  const [profile, setProfile] = useState<IPropfile>()
  const [tabIndex, setTabIndex] = useState(0)
  const { t } = useTranslation()

  const Schema = Yup.object().shape({
    username: Yup.string().required(),
    name: Yup.string().required()
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch
  } = useForm<any>({
    resolver: yupResolver(Schema),
  })

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }
  const OnFinish: SubmitHandler<any> = data => {
    if (data?.name !== undefined) {
      editProfile(data)
    }
  }

  useEffect(() => {
    if (data?.success == true) {
      setProfile(data.data)
      reset({
        username:data.data.username,
        name:data.data.name,
        mobile:data.data.mobile,
        id:data.data.id
      })
    }
  }, [data])


  useEffect(()=>{
    if(resultEdit.data?.success == true){
      ToastSuccess(resultEdit.data.message)
    }
  },[resultEdit])

  return (
    <section className='flexCol gap-4 pageLayout'>
      <Tabs
        className='mb-4'
        value={tabIndex}
        onChange={handleChange}
        aria-label='basic tabs example'
      >
        <Tab
          icon={<PersonIcon />}
          iconPosition='start'
          label={t('Common.Profile')}
        />
        <Tab
          icon={<ShieldIcon />}
          iconPosition='start'
          label={t('Common.Security')}
        />
      </Tabs>
      {tabIndex == 0 && (
        <div className='w-full'>
          <form
            className='w-full'
            onSubmit={handleSubmit((values: any) => OnFinish(values))}
          >
            <div className='w-full flex justify-between gap-10'>
              <InputTxt
                classContainer='!w-full '
                className='w-full'
                control={control}
                errors={errors}
                keyValue='name'
                label={t('Common.name')}
                name='name'
                placeholder={t('Common.name')}
              />

              <InputTxt
                classContainer='!w-full'
                control={control}
                className='w-full'
                errors={errors}
                keyValue='username'
                label={t('Common.username')}
                name='username'
                placeholder={t('Common.username')}
              />
            </div>
            <div className='w-full flex justify-between gap-10'>
              <MobileInput name='mobile' control={control} errors={errors} label='موبایل' keyValue='mobile' classContainer='w-full' />
              <div className="w-full"></div>
            </div>

            <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]'>
              <div className='w-1/3'>
                <ButtonBrand
                  type='submit'
                  //   loading={results.isLoading}
                  key={'buttonForm'}
                  click={OnFinish}
                >
                  <b>{t('Common.saveButton')}</b>
                </ButtonBrand>
              </div>
            </div>
          </form>
        </div>
      )}
      {tabIndex == 1 && <EditPassword />}
    </section>
  )
}
export default PageProfile
