
import {  PAYMENT_PATH } from '../../config/ApiConf'
import { baseQueryWithReAuth } from '../ApiCal'
import { createApi } from '@reduxjs/toolkit/query/react'
import { GetPayments } from '../../interface/EntityPayment'
import { ID } from '../../interface/EntityCommon'
import { ApiResponseProps } from '../../interface/Api'

export const PaymentApi = createApi({
  reducerPath: 'PaymentApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Payment'],
  endpoints: builder => ({
    getPayments: builder.query<GetPayments, void>({
      query: () => ({
        url: `${PAYMENT_PATH}/all`,
        method: 'GET'
      }),
      providesTags: ['Payment']
    }),
    setDefaultPayment: builder.mutation<ApiResponseProps, ID>({
      query: body => ({
        url: `${PAYMENT_PATH}/update/${body.id}`,
        method: 'PUT',
        body: {active:1}
      }),
      invalidatesTags: ['Payment']
    })
  })
})

export const { useGetPaymentsQuery, useSetDefaultPaymentMutation } = PaymentApi
