import { SubmitHandler, useForm } from 'react-hook-form'
import InputTxt from '../../components/shared/form/input/InputTxt'
import NumberCodeInput from '../../components/shared/form/input/NumberCodeInput copy'
import { useTranslation } from 'next-i18next'
import InputTextarea from '../../components/shared/form/input/InputTextarea'
import ButtonBrand from '../../components/shared/button/ButtonBrand'
import {
  useSiteInfoDataQuery,
  useSiteInfoUpdateMutation
} from '../../data/services/SiteInfo'
import { useEffect, useState } from 'react'
import FileInput from '../../components/shared/form/upload/FileInput'
import ImageComponent from '../../components/shared/Html/Image'
import VideoComponent from '../../components/shared/Html/Video'
import Maps from '../../components/shared/map/Map'
import { ToastSuccess } from '../../utility/ToastFunction'
import NumberInput from '../../components/shared/form/input/NumberInput'
import TabBar from '../../layout/tabBar'
import TabBtn from '../../components/shared/Html/TabBtn'
import { handleShowError } from '../../utility/Function'
import { useGetPaymentsQuery, useSetDefaultPaymentMutation } from '../../data/services/Payment'
import { OptionSelect } from '../../interface/Api'
import { PaymentType } from '../../interface/EntityPayment'
import SelectWithoutForm from '../../components/shared/form/input/SelectWithoutForm'
import { datePickerToolbarClasses } from '@mui/x-date-pickers'

function SiteInfoPage() {
  const { t } = useTranslation()
  const { data: dataPayment, isLoading: isLoadingGetPayments } = useGetPaymentsQuery()
  const [tab, setTab] = useState("contact_info");
  const [updateInfo, results] = useSiteInfoUpdateMutation()
  const [changeDefaultPayment, resultChangeDefaultPayment] = useSetDefaultPaymentMutation()
  const { data } = useSiteInfoDataQuery()
  const [deleteImage, setDeleteImage] = useState(false)
  const [deleteVideo, setDeleteVidelo] = useState(false)

  const [defaultPayment, setDefaultPayment] = useState<{label:string,value:string}>()
  const [position, setPosition] = useState<any>([
    data?.data.site_info?.lat,
    data?.data?.site_info.long
  ])

  // options
  const [paymentList, setPaymentList] = useState<{ value: string, label: string }[]>([])

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch
  } = useForm<any>({})

  const OnFinish: SubmitHandler<any> = data => {
    if (data !== undefined) {
      let params = data

      if (deleteVideo || typeof params.instagram_post_video === 'string') {
        delete params['instagram_post_video']
      }

      if (typeof params.instagram_post_image === 'string') {
        delete params['instagram_post_image']
      }

      if (deleteImage) {
        params['instagram_post_image'] = null
      }
      if (deleteVideo) {
        params['instagram_post_video'] = null
      }

      delete params['instagram_post_image_path']
      delete params['instagram_post_video_path']

      updateInfo({
        ...params,
        lat: position[0].toString(),
        long: position[1].toString()
      })
    }
  }

  useEffect(() => {
    if (results.isSuccess) {
      setDeleteImage(false)
      setDeleteVidelo(false)
      ToastSuccess('Common.successTxt')
    } else {
      console.log("error is : ", results?.error)
      // handleShowError(results?.error)
    }
  }, [results])

  useEffect(() => {
    if (data?.data.site_info) {
      let params: any = data.data.site_info
      setPosition([params.lat, params.long])
      reset(params)
    }
  }, [data])
  

  useEffect(() => {
    if (dataPayment?.success === true) {
      const newList = dataPayment.data.data.map((item: PaymentType) => {
        return {
          label: item.title,
          value: item.id.toString()
        }
      })
      setPaymentList(newList)
      const findDefault = dataPayment.data.data.find(item=>item.active === 1)
      if(findDefault){
        setDefaultPayment({value:findDefault?.id.toString(),label:findDefault.title})
      }
    }
  }, [dataPayment])

  useEffect(() => {
    if (resultChangeDefaultPayment.data?.success === true) {
      ToastSuccess(resultChangeDefaultPayment.data.message)
    }
  }, [resultChangeDefaultPayment])

  return (
    <section>
      <form onSubmit={handleSubmit((values: any) => OnFinish(values))}>
        <div className='bg-[--light] FormModal !h-auto flexCol gap-4 p-4'>
          <TabBar>
            <div className='w-full text-nowrap' onClick={() => setTab("contact_info")}><TabBtn active={tab === "contact_info"} txt={t("Txt.contact_info")} /></div>
            <div className='w-full text-nowrap' onClick={() => setTab("instagram")}><TabBtn active={tab === "instagram"} txt={t("Txt.instagram")} /></div>
            <div className='w-full text-nowrap' onClick={() => setTab("socials")}><TabBtn active={tab === "socials"} txt={t("Txt.socials")} /></div>
            <div className='w-full text-nowrap' onClick={() => setTab("SEO")}><TabBtn active={tab === "SEO"} txt={t("Txt.SEO")} /></div>
            <div className='w-full text-nowrap' onClick={() => setTab("sale")}><TabBtn active={tab === "sale"} txt={t("Txt.sale")} /></div>

          </TabBar>
          {tab === "socials" && <>
            <div className='flexRowItemForm'>
              <div className='w-full'>
                <InputTxt
                  errors={errors}
                  label={t('Common.instagram')}
                  name='instagram'
                  control={control}
                  keyValue='instagram'
                />
              </div>
              <div className='w-full'>
                <InputTxt
                  errors={errors}
                  label={t('Common.twitter')}
                  name='twitter'
                  control={control}
                  keyValue='twitter'
                />
              </div>
            </div>
            <div className='flexRowItemForm'>
              <div className='w-full'>
                <InputTxt
                  errors={errors}
                  label={t('Common.facebook')}
                  name='facebook'
                  control={control}
                  keyValue='facebook'
                />
              </div>
              <div className='w-full'>
                <InputTxt
                  errors={errors}
                  label={t('Common.telegram')}
                  name='telegram'
                  control={control}
                  keyValue='telegram'
                />
              </div>
            </div>
            <div className='flexRowItemForm'>
              <div className='w-full'>
                <InputTxt
                  errors={errors}
                  label={t('Common.whatsapp')}
                  name='whatsapp'
                  control={control}
                  keyValue='whatsapp'
                />
              </div>
            </div>
          </>}
          {tab === "contact_info" && <>
            <div className='flexRowItemForm'>
              <div className='w-full'>
                <InputTextarea
                  errors={errors}
                  label={t('Common.address')}
                  name='address'
                  control={control}
                  keyValue='address'
                />
              </div>
              <div className='w-full'>
                <InputTextarea
                  errors={errors}
                  label={t('Menu.about')}
                  name='about'
                  control={control}
                  keyValue='about'
                />
              </div>
            </div>

            <div className='flexRowItemForm'>
              <div className='w-full'>
                <NumberCodeInput
                  length={11}
                  errors={errors}
                  label={t('Common.phone')}
                  name='phone'
                  control={control}
                  keyValue='phone'
                />
              </div>
              <div className='w-full'>
                <InputTxt
                  errors={errors}
                  label={t('Common.email')}
                  name='email'
                  control={control}
                  keyValue='email'
                />
              </div>
            </div>
            <div className=''>
              <p className='LabelClass'>انتخاب موقعیت مکانی</p>
              <div className='w-full flex justify-center'>
                <div className='relative w-[70%] h-[300px]'>
                  <Maps
                    ChangeData={(e: any) => {
                      setPosition([e?.lat, e?.lng])
                    }}
                    canChangeLocation={true}
                    findCurrentLocation={false}
                    editMode={true}
                  />
                </div>
              </div>
            </div>
          </>}

          {tab === "SEO" && <>
            <div className='flexRowItemForm'>
              <div className='w-full'>
                <InputTxt
                  errors={errors}
                  label={t('Common.titleSeo')}
                  name='title_seo'
                  control={control}
                  keyValue='title_seo'
                />
              </div>
              <div className='w-full'>
                <InputTextarea
                  errors={errors}
                  label={t('Common.descriptionSeo')}
                  name='description_seo'
                  control={control}
                  keyValue='description_seo'
                />
              </div>
            </div>
          </>}
          {tab === "instagram" && <>
            <div className='flexRowItemForm'>
              <div className='w-full'>
                <FileInput
                  accept='image/*'
                  getValues={getValues}
                  name='instagram_post_image'
                  title={t('Txt.instagram_image')}
                  control={control}
                  errors={errors}
                />
              </div>
              <div className='w-full'>
                <FileInput
                  accept='.mp4'
                  getValues={getValues}
                  name='instagram_post_video'
                  title={t('Txt.instagram_post_video')}
                  control={control}
                  errors={errors}
                />
              </div>
            </div>

            <div className='flexRow gap-4'>
              <div className='w-[45%]'>
                <ImageComponent
                  className='w-full h-full object-contain rounded-[--radius]'
                  src={data?.data.site_info.instagram_post_image_path ?? ''}
                />
              </div>
              <div className='w-[45%] rounded-[10px]  '>
                <VideoComponent
                  className='w-full h-full bg-[--murky] rounded-[--radius]'
                  src={data?.data.site_info.instagram_post_video_path ?? ''}
                />
              </div>
            </div>
            <div className='w-full flex justify-evenly'>
              <ButtonBrand
                className='mb-4 !w-[100px]'
                loading={results.isLoading}
                click={() => {
                  // setValue('instagram_post_image', null)
                  setDeleteImage(true)
                  OnFinish({ ...getValues() })
                }}
                type='submit'
              >
                {t('Common.deleteImage')}
              </ButtonBrand>
              <ButtonBrand
                className='mb-4 !w-[100px]'
                loading={results.isLoading}
                click={() => {
                  setDeleteVidelo(true)
                  // setValue('instagram_post_video', '')
                  OnFinish({ ...getValues() })
                }}
                type='submit'
              >
                {t('Common.deleteVideo')}
              </ButtonBrand>
            </div>
          </>}
          {tab === "sale" && <>
            <div className='w-full'>
              {/* <NumberInput
                errors={errors}
                label={t('Common.limit_purchase_wholesale')}
                name='limit_purchase_wholesale'
                control={control}
                keyValue='limit_purchase_wholesale'
              /> */}
              <div className="flexRowItemForm">
                <div className="w-full">
                  <NumberInput
                    errors={errors}
                    label={t('Common.post_price')}
                    name='post_price'
                    control={control}
                    keyValue='post_price'
                  />
                </div>
                <div className="w-full">
                  <NumberInput classContainer=''
                    errors={errors}
                    label={t('Common.tax_percent')}
                    name='tax_percent'
                    control={control}
                    keyValue='tax_percent'
                  />
                </div>
              </div>
              <div className="flexRowItemForm">
                <div className="w-full">
                  <NumberInput
                    errors={errors}
                    label={t('Common.minFirsOrderPrice')}
                    name='min_price_wholesale'
                    control={control}
                    keyValue='min_price_wholesale'
                  />
                </div>
                <div className="w-full">
                  <SelectWithoutForm
                    classContainer='!w-full !h-[50px]'
                    defaultValue={defaultPayment}
                    label="انتخاب درگاه پرداخت"
                    options={paymentList}
                    placeholder="انتخاب درگاه پرداخت
 "                   getValue={(e: { label: string; value: string }) => {
                      changeDefaultPayment({ id: parseInt(e.value) })
                    }}
                  />
                </div>
              </div>
            </div>
          </>}
          <div className='flexRow justify-center w-full mt-20'>
            <div className='w-[50%]'>
              <ButtonBrand
                loading={results.isLoading}
                click={() => { }}
                type='submit'
              >
                {t('Common.saveButton')}
              </ButtonBrand>
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

export default SiteInfoPage
