import { ChangeEvent, FC, HTMLProps } from "react"
import Select from 'react-select';

interface IProps {
    placeholder: string,
    label: string,
    value?: any,
    getValue: Function,
    options: { label: string, value: string }[],
    classContainer?: HTMLProps<HTMLElement>['className'],
    classInput?: HTMLProps<HTMLElement>['className'],
    defaultValue?:{ label: string, value: string }
}
const SelectWithoutForm: FC<IProps> = ({ label, placeholder, value, getValue, options,classContainer,classInput,defaultValue }) => {
    return (
        <div className={`InputLabelClass ${classContainer}`}>
            <label htmlFor="" className="LabelClass">{label}</label>
            <Select
                className={`inputSelect heightInput ${classInput}`}
                classNamePrefix="select"
                isDisabled={false}
                isRtl={true}
                isSearchable={true}
                options={options}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={(value) => {
                    getValue(value)
                }
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 999999 }) }}
            />

        </div>
    )
}
export default SelectWithoutForm