import {
  BlogList,
  BlogAddPropsApi,
  BlogRemovePropsApi,
  BlogUpdatePropsApi,
  BlogDetail,
  BlogListFilterApi,
  BlogListApi,
  BlogDataType
} from '../../interface/EntityBlog'

import { ChangeToFormData } from '../../utility/Function'
import { BLOG_GET_PATH, BLOG_PATH, PROFILE_PATH } from '../../config/ApiConf'
import { baseQueryWithReAuth } from '../ApiCal'
import { createApi } from '@reduxjs/toolkit/query/react'
import { ID } from '../../interface/EntityCommon'
import { ProfileApiResponse } from '../../interface/EntityProdile'

export const ProfileApi = createApi({
  reducerPath: 'ProfileApi',
  baseQuery: baseQueryWithReAuth,

  tagTypes: ['Profile'],

  endpoints: builder => ({
    getProfile: builder.query<ProfileApiResponse, void>({
      query: () => ({
        url: PROFILE_PATH,
        method: 'GET'
      }),
      providesTags: ['Profile']
    }),
    BlogDetail: builder.query<BlogDetail, ID>({
      query: body => ({
        url: BLOG_GET_PATH + '/' + body.id,
        method: 'GET'
      }),
      providesTags: ['Profile']
    }),
    BlogAdd: builder.mutation<any, BlogAddPropsApi>({
      query: body => ({
        url: BLOG_PATH,
        method: 'POST',

        body: ChangeToFormData(body)
      }),
      invalidatesTags: ['Profile']
    }),

    editProfile: builder.mutation<
      ProfileApiResponse,
      { username?: string; name?: string; password?: string; mobile?: string ,id:number}
    >({
      query: body => ({
        url: `admin/admin/${body.id}`,
        method: 'PUT',
        body: ChangeToFormData(body)
      }),
      invalidatesTags: ['Profile']
    }),

    BlogRemove: builder.mutation<void, BlogRemovePropsApi>({
      query: body => ({
        url: BLOG_PATH + '/' + body.id,
        method: 'DELETE'
      }),
      invalidatesTags: ['Profile']
    })
  })
})

export const {
  useGetProfileQuery,
  useBlogDetailQuery,
  useBlogAddMutation,
  useEditProfileMutation,
  useBlogRemoveMutation
} = ProfileApi
