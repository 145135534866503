import { useTranslation } from "next-i18next"
import ButtonBrand from "../../shared/button/ButtonBrand"
import { useDispatch } from "react-redux"
import { setSenderTypeFormMode } from "../../../data/slice/senderType/SenderTypeForm"
import PlusIcon from "../../shared/Icon/Plus"

const HeaderSenderTypePage=()=>{

    const {t} = useTranslation()
    const dispatch = useDispatch()
    return (
        <section className='flex flex-col gap-10'>
          <div className='w-ful flex justify-between items-center'>
            <h1 className='text-lg md:text-2xl'>{t('Menu.senderType')}</h1>
            <div className='flex justify-start items-center gap-2 md:gap-10'>
          
              {/* <ButtonBrand
                className='!text-nowrap'
                click={() => {
                  dispatch(setDiscountFormMode('filter'))
                }}
              >
                <div className='flexRow items-center gap-2'>
                  <span>
                    <FilterAltIcon fill='var(--light)' />
                  </span>
                  <b> {t('Txt.filter')} </b>
                </div>
              </ButtonBrand> */}
              <ButtonBrand className='!text-nowrap'
                click={() => {
                  dispatch(setSenderTypeFormMode('add'))
                }}
              >
                <div className='flexRow items-center gap-2 !flex-nowrap !text-nowrap'>
                  <span>
                    <PlusIcon fill='var(--light)' />
                  </span>
                  <b className='!text-nowrap'> {t('Txt.addSenderType')} </b>
                </div>
              </ButtonBrand>
            </div>
          </div>
        </section>
      )
}

export default HeaderSenderTypePage