
import { useTranslation } from 'next-i18next'
import InputTxt from '../../shared/form/input/InputTxt'
import NumberInput from '../../shared/form/input/NumberInput'
import { FormComponentProps } from '../../../interface/Element'
import InputTextarea from '../../shared/form/input/InputTextarea'
import CustomSelect from '../../shared/form/input/CustomSelect'



export interface IUser {
    value: number,
    id: number,
    label: string,
    mobile?: string,
    image: string | undefined | any,
    fullName: string
}

export default function FormItemSenderype({ control, getValues, setValue, errors, watch }: FormComponentProps) {

    const { t } = useTranslation()

    return (
        <div className='flexCol flex-wrap w-full gap-5'>
            <div className="inputContainersForms">
                <InputTxt classContainer='w-full'
                    control={control}
                    errors={errors}
                    keyValue='name'
                    label={t("Common.name")}
                    name='name'
                    placeholder={t("Common.name")} />
                <InputTxt classContainer='w-full'
                    control={control}
                    errors={errors}
                    keyValue='title'
                    label={t("Common.title")}
                    name='title'
                    placeholder={t("Common.title")} />
            </div>
            <div className="inputContainersForms">
                <NumberInput
                    control={control} errors={errors} keyValue='allowed_number' label={t("Common.cost")} name='cost' placeholder={t("Common.cost")} />
                    <CustomSelect classContainer='h-[49px]' control={control} keyValue='user_type' errors={errors} label={t("Common.selectUserType")} name='user_type' optionItem={[
                    { label: t("Common.general"), value: "general" }, 
                    { label: t("Common.wholesale"), value: "wholesale" }, 
                    { label: t("Common.single"), value: "single" }
                    ]} />
                {/* <CustomSelect classContainer='h-[49px]' control={control} keyValue='default' errors={errors} label={t("Common.default")} name='default' optionItem={[
                    { label: t("Common.default"), value: "1" },
                     { label: t("Common.undefault"), value: "0" }]} /> */}
            </div>

            <div className="inputContainersForms">
                {/* <CustomSelect classContainer='h-[49px]' control={control} keyValue='user_type' errors={errors} label={t("Common.selectUserType")} name='user_type' optionItem={[
                    { label: t("Common.general"), value: "general" }, 
                    { label: t("Common.wholesale"), value: "wholesale" }, 
                    { label: t("Common.single"), value: "single" }
                    ]} /> */}
                <InputTextarea errors={errors} label={t("Common.description")} name="description" control={control}
                    keyValue="description" />
            </div>
        </div>
    )
}
