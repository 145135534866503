import * as Yup from "yup"

import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'



import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "next-i18next"

import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../data/store"
import ModalFull from "../../shared/modal/ModalFull"
import { setSenderTypeFormMode } from "../../../data/slice/senderType/SenderTypeForm"
import PageMarginBottom from "../../shared/Html/PageMarginBottom"
import ButtonBrand from "../../shared/button/ButtonBrand"
import FormItemSenderype from "./FormItemSenderype"
import { useAddSenderTypeMutation } from "../../../data/services/SenderType"
import { ToastError, ToastSuccess } from "../../../utility/ToastFunction"


export default function AddSenderType() {

    const { t } = useTranslation();
    const DataMod = useSelector((state: RootState) => state.SenderTypeForm);
    const [OpenAdd, setOpenAdd] = useState(false);
    const [Add, results] = useAddSenderTypeMutation()
    const dispatch = useDispatch()
    const Schema = Yup.object().shape({
        name: Yup.string().required(t("Validation.require")),
        description: Yup.string().required(t("Validation.require")),
        title: Yup.string().required(t("Validation.require"))
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useForm<any>({
        resolver: yupResolver(Schema)
    });

    const OnFinish: SubmitHandler<any> = (data) => {

        if (data?.name !== undefined) {
            // console.log("change : ",data)
            Add(data);
        }
    }

    useEffect(() => {
        if (DataMod.mode === 'add') {
            setOpenAdd(true);
            reset();
        } else {
            setOpenAdd(false);
        }
    }, [DataMod.mode, reset])

    useEffect(() => {
        if (results.isSuccess) {
            ToastSuccess(t("Common.successAlarm"))
            setOpenAdd(false);
            dispatch(setSenderTypeFormMode("none"));
        }
        if (results.isError) {
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    return (
            <ModalFull
                ChangeOpen={
                    (open: boolean) => {
                        setOpenAdd(open);
                        dispatch(setSenderTypeFormMode(open ? "add" : "none"));
                    }
                }
                Open={OpenAdd} title={t("Txt.addSenderType")}>
                <div className="FormModal !h-auto">
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItemSenderype control={control} errors={errors} getValues={getValues} setValue={setValue} watch={watch} />
                        <div className='w-full flex justify-center mt-10'>
                            <div className="w-1/3">
                                <ButtonBrand type='submit' 
                                // loading={results.isLoading}
                                 key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>
                    <PageMarginBottom />
                </div>
            </ModalFull>
    )
}
