
import { useSelector } from 'react-redux'
import { RootState } from '../../../data/store'
import AddSenderType from './AddSenderType'
import EditSenderType from './EditSenderType'
import DeleteSenderType from './DeleteSenderType'



export default function FormSenderType() {

  const mode = useSelector((state: RootState) => state.SenderTypeForm.mode)

  return (
    <>
      <div className='w-full h-full bg-[--light] p-2 rounded-[--radius]'>


        {/* {mode === "delete" && <DeleteBannerComponent />} */}
        {mode === "add" && <AddSenderType />}
        {mode === "edit" && <EditSenderType />}
        {mode === "delete" && <DeleteSenderType />}
        
      </div>
    </>
  )
}
