import Img from '../../asset/img/logo.png'
import MenuItem from './MenuItem'
import MenuItems from './MenuItems'
import { Menus } from '../../config/Menu'
import { useTranslation } from 'next-i18next'
import { usePermissionQuery } from '../../data/services/Permission'
import { Link } from 'react-router-dom'
import { FC } from 'react'

interface IProps{
    handleSidebar:Function,
}


const Sidebar:FC<IProps> = ({handleSidebar}) => {
    const { t } = useTranslation();
    const { data } = usePermissionQuery();

    return (
        <>
            <div className="flex flex-col gap-y-[12px]  text-sm font-bold cursor-pointer  text-[--murkyLight] h-screen overflow-y-auto z-10">
                <div className='flex justify-center w-full h-44'>
                    <Link to="https://tiademco.com/" target='_blank'>
                    <img className='w-full h-full object-contain  aspect-auto' src={Img} alt="Logo" />

                    </Link>
                </div>
                <div className="flex flex-col px-[12px] py-[12px] gap-y-4">
                    {
                        Menus?.map((item) => {
                            if (item.keyPermission !== "public" && !data?.data.find((value) => value.key === item.keyPermission))
                                return '';

                            if (item.haveChildren)
                                return <MenuItems  keys={item.name} icon={item.icon} title={t(`Menu.${item.name}`)} children={item?.children ?? []} />
                            return item.name !=="permission" && <MenuItem   {...item} />;
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Sidebar