import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import PasswordTxt from '../../../shared/form/input/PasswordTxt'
import ButtonBrand from '../../../shared/button/ButtonBrand'
import { useEditProfileMutation, useGetProfileQuery } from '../../../../data/services/Profile'
import { useEffect } from 'react'
import { ToastSuccess } from '../../../../utility/ToastFunction'

const EditPassword = () => {
  const { t } = useTranslation()
    const { data, isLoading } = useGetProfileQuery()
  
  const [editProfile,resultEdit] = useEditProfileMutation()
  const Schema = Yup.object().shape({
    password: Yup.string().required(),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch
  } = useForm<any>({
    resolver: yupResolver(Schema)
  })
  const OnFinish: SubmitHandler<any> = data => {
    if (data?.password !== undefined) {
        editProfile(data)
    }
  }

    useEffect(() => {
      if (data?.success == true) {
        reset({
          username:data.data.username,
          name:data.data.name,
          id:data.data.id
        })
      }
    }, [data])

  useEffect(()=>{
    if(resultEdit.data?.success == true){
      ToastSuccess(resultEdit.data.message)
    }
  },[resultEdit])
  return (
    <div className='w-full'>
      <form
        className='w-full'
        onSubmit={handleSubmit((values: any) => OnFinish(values))}
      >
        <div className='w-full flex justify-between items-center gap-10'>
          <PasswordTxt
            classContainer='w-full'
            control={control}
            errors={errors}
            keyValue='password'
            label={t('Common.password')}
            name='password'
            placeholder={t('Common.password')}
          />
          <div className="w-full"></div>
        </div>
        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]'>
          <div className='w-1/3'>
            <ButtonBrand
              type='submit'
              //   loading={results.isLoading}
              key={'buttonForm'}
              click={OnFinish}
            >
              <b>{t('Common.saveButton')}</b>
            </ButtonBrand>
          </div>
        </div>
      </form>
    </div>
  )
}
export default EditPassword
