import { useEffect, useState } from 'react'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import FormItem from '../FormItem';
import { ToastError, ToastSuccess } from '../../../../../utility/ToastFunction';
import ButtonBrand from '../../../../shared/button/ButtonBrand';
import ModalFull from '../../../../shared/modal/ModalFull';
import { useRoleEditMutation } from '../../../../../data/services/Role';
import { useRolePermissionAddMutation, useRolePermissionQuery } from '../../../../../data/services/RolePermission';
import PageMarginBottom from '../../../../shared/Html/PageMarginBottom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../data/store';
import { usePermissionQuery } from '../../../../../data/services/Permission';
import { setRoleFormMode } from '../../../../../data/slice/role/RoleForm';

export default function ViewRole({ openModal, ChangeOpen }: { openModal: boolean, ChangeOpen: Function }) {

    const { t } = useTranslation();
    const Data = useSelector((state: RootState) => state.RoleForm.data);
    const dispatch= useDispatch()
    const {data:PermissionsData}=usePermissionQuery()
    const { data:DataPermission } = useRolePermissionQuery({ id: Data?.id ?? 0 });
    const [OpenAdd, setOpenAdd] = useState(false);
    const [permissions, setPermissions] = useState<string[]>([])
    const [roleEdit, results] = useRoleEditMutation()
    const [RolePermissionAdd, resultPermission] = useRolePermissionAddMutation()
    const [loading, setLoading] = useState(results.isLoading)
    const Schema = Yup.object().shape({
        name: Yup.string().required(t("Validation.require")),
    });

    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = useForm<any>({
        resolver: yupResolver(Schema),
      
    });

    const OnFinish: SubmitHandler<any> = (data) => {

        if (data?.name !== undefined) {
            setLoading(true)
            let keys = Object.keys(data);
            let permissions = keys.filter((item) => item !== 'name' && data[item])
            setPermissions(permissions)

            const formData= new FormData()

            formData.append("name",data.name)
            formData.append("role_id",Data?.id? Data.id.toString() :"0")

            permissions.forEach((item,index)=>{
                formData.append(`permissions[${index}][id]`,item)
            })

            // roleEdit({ name: data.name,id:Data?.id??0 });
            // roleEdit({ name: data.name,id:Data?.id??0 });
            roleEdit(formData);
        }
    }

    useEffect(() => {
        setOpenAdd(openModal);
        reset();
    }, [openModal, reset])

    useEffect(() => {
        if (results.isSuccess) {
            
            RolePermissionAdd({
                role_id: Data?.id,
                permissions
            })


        }
        if (results.isError) {
            setLoading(false);
            ToastError(t("Common.errorAlarm"))
        }
    }, [results])


    useEffect(() => {
        if (resultPermission.isSuccess) {
            setLoading(false);
            ToastSuccess(t("Common.successAlarm"))
            setOpenAdd(false);
            dispatch(setRoleFormMode("none"))
        }
        if (resultPermission.isError) {
            setLoading(false);
            ToastError(t("Common.errorAlarm"))
        }
    }, [resultPermission])

    useEffect(()=>{
        if(DataPermission?.data){
            let values:any={};
            values['name']=Data?.name
            PermissionsData?.data.forEach((item)=>values[item.id]=DataPermission.data.findIndex((data)=>data.permission_id===item.id)<0?false:true)
          
      
            reset(values);
        }
    },[DataPermission])

    return (
        <>

            <ModalFull ChangeOpen={(open: boolean) => { setOpenAdd(open); ChangeOpen(open); }} Open={OpenAdd} title={t("Txt.addRole")}>
                <div className="FormModal !h-auto">
                    <form onSubmit={handleSubmit((values: any) => OnFinish(values))}    >
                        <FormItem control={control} errors={errors} getValues={getValues} setValue={setValue} />

                        <div className='w-full flex justify-center mt-10  bottom-5 left-[3%]' >
                            <div className="w-1/3">
                                <ButtonBrand type='submit' loading={loading} key={"buttonForm"} click={OnFinish} >
                                    <b>{t("Common.saveButton")}</b>
                                </ButtonBrand>
                            </div>
                        </div>
                    </form>

                    <PageMarginBottom />


                </div>

            </ModalFull>

        </>
    )
}
